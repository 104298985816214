import * as React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Modal as PUIModal, ModalProps } from '@toasttab/buffet-pui-modal'
import { Button, ButtonProps } from '@toasttab/buffet-pui-buttons'

//#region Utils
const Loader = () => {
  return (
    <div
      className='flex flex-col items-center justify-center h-full'
      style={{ minHeight: '100px' }}
    >
      <MerryGoRound />
    </div>
  )
}
//#endregion

interface CustomModalProps extends ModalProps {
  title: React.ReactNode
}

const Modal = ({
  children,
  title,
  ...props
}: React.PropsWithChildren<CustomModalProps>) => (
  <PUIModal position='pin-right' size='lg' {...props}>
    <PUIModal.Header>{title}</PUIModal.Header>
    <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
  </PUIModal>
)

//#region Alert
const AlertModal = ({
  children,
  ...props
}: React.PropsWithChildren<Omit<CustomModalProps, 'position' | 'size'>>) => (
  <Modal position='pin-flex' size='xl' {...props}>
    {children}
  </Modal>
)
//#endregion

//#region Form

type FormContextProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void | undefined
  id: string
}

const FormContext = React.createContext<FormContextProps>(
  {} as unknown as FormContextProps
)

const FormModal = ({
  children,
  onSubmit,
  id,
  ...modal
}: React.PropsWithChildren<CustomModalProps & FormContextProps>) => (
  <FormContext.Provider value={{ onSubmit, id }}>
    <Modal {...modal}>{children}</Modal>
  </FormContext.Provider>
)

const FormBody = ({ children }: { children?: React.ReactNode }) => {
  const value = React.useContext(FormContext)

  return (
    <PUIModal.Body>
      <form onSubmit={value.onSubmit} id={value.id} className='space-y-4'>
        {children}
      </form>
    </PUIModal.Body>
  )
}

const FormSubmit = ({
  children,
  ...props
}: ButtonProps & React.JSX.IntrinsicElements['button']) => (
  <Button type='submit' form={React.useContext(FormContext).id} {...props}>
    {children}
  </Button>
)
const FormCancel = ({
  children,
  ...props
}: ButtonProps & React.JSX.IntrinsicElements['button']) => (
  <Button
    type='reset'
    variant='secondary'
    form={React.useContext(FormContext).id}
    {...props}
  >
    {children}
  </Button>
)

//#endregion

/**
 * This component is designed to wrap a modal which needs to suspend itself.
 * The main use is for forms where you need to load information in order to
 * show the form itself. The current modal has a suspense boundary inside of
 * the modal, but when you need to load information BEFORE you show the modal,
 * you need to provide an suspense boundary OUTSIDE the hooks that suspend.
 *
 * You should only need this component if:
 * - data loading cannot be contained inside of the <Modal /> (as <Modal /> has it's own suspense boundary)
 */
const ModalSuspenseWrapper = ({
  children,
  ...props
}: Pick<CustomModalProps, 'title' | 'position' | 'size'> & {
  children?: React.ReactNode
}) => (
  <React.Suspense
    fallback={
      <Modal isOpen {...props}>
        <Loader />
      </Modal>
    }
  >
    {children}
  </React.Suspense>
)

const AlertModalSuspenseWrapper = ({
  children,
  ...props
}: Pick<CustomModalProps, 'title' | 'position' | 'size'> & {
  children?: React.ReactNode
}) => (
  <React.Suspense
    fallback={
      <AlertModal isOpen {...props}>
        <Loader />
      </AlertModal>
    }
  >
    {children}
  </React.Suspense>
)

//we want to follow the same API pattern as the original buffet-pui components

Modal.Footer = PUIModal.Footer
Modal.Body = PUIModal.Body
Modal.Loader = Loader

FormModal.Footer = PUIModal.Footer
FormModal.Body = FormBody
FormModal.Loader = Loader
FormModal.Submit = FormSubmit
FormModal.Cancel = FormCancel

AlertModal.Footer = PUIModal.Footer
AlertModal.Body = PUIModal.Body
AlertModal.Loader = Loader

export {
  AlertModal,
  Modal,
  FormModal,
  ModalSuspenseWrapper,
  AlertModalSuspenseWrapper
}
