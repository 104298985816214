/* eslint-disable no-template-curly-in-string */

export default {
  forms: {
    8655: {
      alert:
        "We're now electronically collecting IRS Form 8655. You must submit all forms to process payroll and file taxes for your business.",
      description:
        'The IRS Form 8655 Reporting Agent Authorization authorizes Toast Payroll (i.e Strategy Execution Partners, LLC) to file and pay federal employment taxes on behalf of your business(es). For more information, and to review the instructions for the IRS 8655, <0>irs.gov.</0>',
      descriptionContinuePayroll:
        'To continue processing payroll, we need you to submit the IRS Form 8655 Reporting Agent Authorization. The form authorizes Toast Payroll (i.e Strategy Execution Partners, LLC) to file and pay federal employment taxes on behalf of your business(es). For more information, and to review the instructions for the IRS 8655, <0>irs.gov.</0>',
      download: {
        alert:
          'You can download your signed IRS Form 8655. The IRS Form 8655 Reporting Agent Authorization authorizes Toast Payroll (i.e Strategy Execution Partners, LLC) to file and pay federal employment taxes on behalf of your business.',
        alertMultiple:
          'You can download your signed IRS Forms 8655. The IRS Form 8655 Reporting Agent Authorization authorizes Toast Payroll (i.e Strategy Execution Partners, LLC) to file and pay federal employment taxes on behalf of your business.',
        alertDownload: '<0>Download</0>',
        title: 'Download IRS Form 8655',
        options: {
          feinTitle: 'Business',
          feinText: 'Download forms signed by the business you are logged into',
          dateTitle: 'Date range',
          dateText:
            'Download all signed forms within a date range across Payroll'
        },
        loading: 'Loading...',
        cancel: 'Cancel',
        download: 'Download'
      },
      actions: {
        start: 'Start',
        submitted: 'RECEIVED',
        submit: 'Submit',
        back: 'Back'
      },
      multiple: {
        alert:
          "We're now electronically collecting filing IRS Form 8655. You must submit all forms to process payroll and file taxes for your businesses.",
        title: 'Form 8655 Reporting Agent Authorization',
        table: {
          business: 'Business',
          status: 'Form 8655 status'
        }
      },
      validations: {
        contact: {
          required: 'Contact person is required',
          valid:
            'The only characters allowed are alpha, ampersand (&), hyphen (-), less-than sign (<), and space.'
        },
        title: 'Title is required',
        date: 'Date is required',
        signature: {
          required: 'Signature is required',
          invalid:
            'The digital signature you provided cannot be accepted. Please try signing your name again.'
        }
      },
      snackBarStatus: {
        success:
          'Form 8655 submitted. You can download your form from Reports under Tax Filing',
        error: 'Form 8655 cannot be submitted. Try submitting the form again'
      },
      formFields: {
        pageHeader: 'Form 8655 Reporting Authorization Report',
        taxpayer: 'Taxpayer',
        taxpayerNameField: '1a Name of taxpayer',
        einField: '2 Employer identification number (EIN)',
        addressField: '3 Address (number, street, and room or suite no.)',
        cityState: 'City or town, state, and ZIP code',
        contactPerson: '6 Contact person',
        daytimePhone: '7 Daytime telephone number',
        typeHere: 'Type here',
        authorizationAgreementTitle: 'Authorization Agreement',
        authorizationAgreement:
          '<strong>I certify I have the authority to execute this form and authorize disclosure of otherwise confidential information on behalf of the taxpayer.</strong> <br/> I understand that this agreement does not relieve me, as the taxpayer, of the responsibility to ensure that all tax returns are filed and that all deposits and payments are made and that I may enroll in the Electronic Federal Tax Payment System (EFTPS) to view deposits and payments made on my behalf. If line 15 is completed, the reporting agent named above is authorized to sign and file the return indicated, beginning with the quarter or year indicated. If any starting dates on line 16 are completed, the reporting agent named above is authorized to make deposits and payments beginning with the period indicated. Any authorization granted remains in effect until it is terminated or revoked by the taxpayer or reporting agent. I am authorizing the IRS to disclose otherwise confidential tax information to the reporting agent relating to the authority granted on line 15 and/or line 16, including disclosures required to process Form 8655. Disclosure authority is effective upon signature of taxpayer and IRS receipt of Form 8655. The authority granted on Form 8655 will not revoke any Power of Attorney (Form 2848) or Tax Information Authorization (Form 8821) in effect.',
        select: 'Select an option',
        certifyStatement:
          'I certify I have the authority to execute this form and authorize disclosure of otherwise confidential information on behalf of the taxpayer.',
        date: 'Date',
        fax: '8 Fax number',
        title: 'Title'
      }
    }
  },
  audit: {
    action: {
      posted: 'posted',
      opened: 'started',
      reset: 'reset',
      postponed: 'postponed payment (missed deadline) to {{postponedDate}} for',
      checks: 'forced live checks (missed deadline) for'
    },
    description: '{{whom}} {{action}} this payroll at {{time}}'
  },
  common: {
    contactCare: 'Contact care',
    earning: 'Earning',
    hours: 'Hours',
    amountDolar: 'Amount ($)',
    filters: {
      label: 'Filters',
      clear: 'Clear filters'
    },
    action: {
      open: 'open',
      run: 'run',
      buttons: {
        add: 'Add',
        advanced: 'Advanced actions',
        calculate: 'Calculate',
        cancel: 'Cancel',
        close: 'Close',
        yesContinue: 'Yes, continue',
        continue: 'Continue',
        next: 'Next',
        okay: 'Okay',
        processing: 'Processing...',
        submit: 'Submit',
        view: 'View',
        reset: 'Reset payroll',
        run: 'Start payroll',
        preparing: 'Preparing',
        calculating: 'Calculating',
        ignoreTips: 'Ignore unreceived tips',
        ignoreTimesheets: 'Ignore unapproved time',
        ignoreTimesheetsAndTips: 'Ignore unapproved time and tips'
      },
      choose: 'Choose...'
    },
    yes: 'Yes',
    no: 'No',
    modal: {
      header: 'Sorry! Something went wrong.',
      message: {
        errors: {
          deduction:
            'Unable to edit deductions. Please wait a few minutes and try again. If this issue continues, <3> contact Customer Care. </3> ',
          reset:
            'Your payroll failed to reset. Please wait a few minutes and try again. If this issue continues,  <3> contact Customer Care. </3>',
          open: 'Your payroll failed to open. Please wait a few minutes and try again. If this issue continues,  <3> contact Customer Care. </3>',
          calculate:
            'Your payroll cannot be calculated. Please wait a few minutes and try again. If this issue continues,  <3> contact Customer Care. </3>',
          post: 'Your payroll failed to post. Please wait a few minutes and try again. If this issue continues,  <3> contact Customer Care. </3>',
          generic:
            'We were unable to apply changes to your payroll, please contact support or retry',
          saveEarning:
            'Unable to save earning. Please wait a few minutes and try again. If this issue continues,  <3> contact Customer Care. </3>'
        },
        reset:
          'Resetting payroll will undo any changes you have made and return the payroll to its initial state.',
        ignoreTips:
          'Some of your employees might not receive the correct payment for the tips they earned. Make sure you account for all tips earned before paying your employees.',
        ignoreTimesheets:
          'Some of your employees might not receive the correct payment for the hours they worked. Make sure you account for all hours worked before  paying your employees.',
        ignoreTimesheetsAndTips:
          'Some of your employees might not receive the correct payment for the hours they worked and the tips they earned. Make sure you account for all hours worked and tips earned before paying your employees.',
        highNumber: {
          header: 'There is a very high number for the field(s):',
          item: '{{field}}: ${{number}}',
          footer: 'Are you sure you want to continue and save?'
        }
      },
      title: {
        reset: 'Reset Payroll?',
        confirmMissingData: 'Are you sure you want to start payroll?',
        highNumber: 'Confirm earning amount'
      },
      loading: {
        reset: 'Resetting payroll...'
      }
    },
    next: 'Next',
    noChange: 'No Change',
    previous: 'Previous',
    validation: {
      required: 'Required'
    },
    placeholders: {
      search: 'Search...'
    },
    employee: 'Employee'
  },
  disclosure: {
    title:
      'Payments in certain states are processed by Toast Processing Services LLC.',
    target: 'Click Here',
    description: '{{-link}} for details and license information.',
    attention:
      '<strong>Attention Maine Employers:</strong> Please click the below link(s) for contact information for tax agencies if you wish to verify that withholding payments have been made and/or properly credited on your behalf.',
    federalWitholding: 'Federal Employer Withholding',
    maineWitholding: 'Maine Employer Withholding'
  },
  employees: {
    subtitle: 'Review, add, or modify employee earnings and deductions',
    title: 'Employees'
  },
  errors: {
    schedule: {
      default:
        'Unable to schedule payroll at this time. Please contact support.',
      OtherPayrollScheduled:
        'You already have a calculated payroll for this pay group. In order to calculate this payroll, please post or edit your other payroll.',
      PayPeriodMustBeOPENED:
        'Please refresh the page. It seems like the payroll has changed in the background.',
      PayrollLocked:
        'This operation conflicted with another operation already underway. Please retry the operation.'
    },
    post: {
      NotActiveCustomer:
        'You are unable to post payroll because your account is inactive at this time. Please reach out to support if you believe you were incorrectly prevented from posting payroll',
      IncompleteBankInformation:
        'You are unable to post payroll because your are missing bank account information. Please reach out to support if you believe you were incorrectly prevented from posting payroll',
      InvalidStatus:
        'Looks like something has changed, the Payroll Status is Invalid for this type of Action. Please refresh the page and try again.',
      IncompleteBankAccount:
        'You cannot post this payroll as it does not have a bank account tied to it. Please contact support to add the appropriate bank account.',
      PayPeriodArchived:
        'You cannot post this payroll as it currently archived. In order to post please unarchive first.'
    },
    import: {
      LockConflict:
        'An internal timesheet import is currently executing. Once it finishes, you will be able to advance to the next step of payroll.',
      UnknownError:
        'Unable to Import Payroll, Please try again or contact support'
    },
    run: {
      PayPeriodLockConflict: 'Please refresh the page and try again.',
      PreviousYearOpen:
        'Before processing payroll(s) in a new calendar year, the previous calendar year must be closed. This happens automatically on or before January 1st (depending on when the last payroll in the current year processes).\n\nThis payroll cannot currently be processed, as the previous calendar year has not yet been closed. It should automatically close in the coming days. If you need to process urgently, please contact support.',
      IncompleteBankAccount:
        'You cannot open this payroll as it does not have a bank account tied to it. Please contact support to add the appropriate bank account.',
      InvalidStatus:
        'This payroll has changed status in the background. Please refresh the page and retry the operation.'
    },
    saveEarning: {
      PayTypeRestrict:
        'Cannot save earnings because the payment type restricts it..'
    },
    outOfBalance:
      'Your payroll is out of balance by ${{value}}. Try recalculating the payroll, and if the issue persists please contact {{link}} for help.',
    prohibited: 'Prohibited',
    generic: 'Unknown error, Please try again or contact support'
  },
  payrolls: {
    list: {
      upcoming: 'Upcoming Payrolls',
      past: 'Past Payrolls'
    },
    pastPayrollsTab: {
      noPast: 'No Past Payrolls',
      name: 'Name',
      payPeriod: 'Pay Period',
      payDate: 'Pay date',
      postedAt: 'Posted at',
      postedBy: 'Posted by',
      totalExpense: 'Total Expense'
    },
    dueDate: {
      today: 'Today',
      due: 'Due {{deadline}}'
    },
    header: {
      manageLinkText: 'Manage timesheets',
      title: 'Payroll'
    },
    title: {
      payrollReminders: 'Payroll reminders',
      expenses: 'Expenses',
      expenseReportManager: 'Expense report manager',
      quickActions: 'Quick Actions',
      payrollManualChecks: 'Manual Checks',
      payrollToDos: 'Payroll to dos',
      payrollQuickCalcs: 'Quick calcs'
    },
    upcomingPayrollTile: {
      archive: {
        header: 'Archive Payroll?',
        body: 'By archiving, you confirm you do not wish to process this particular payroll. Any hours or earnings tied to this payroll will go unprocessed and unpaid.',
        cancel: 'Cancel',
        ok: 'Archive',
        tooltip: 'Archive Payroll'
      },
      runEarly: {
        header: 'Are you sure you want to start payroll early?',
        body: 'The Pay Date for this payroll is more than one week away. Processing early is encouraged, but please ensure this is the payroll you intend to process and the Pay Date is in line with expectations.',
        cancel: 'Cancel'
      },
      continuePayroll: 'Continue',
      dueDate: 'DUE DATE',
      unableToPost:
        'Your business cannot process payroll due to an issue with your Toast Payroll account. <0>Contact care</0>',
      noUpcoming: "You don't have any upcoming payrolls.",
      offcycle: 'Off-cycle: ',
      payDate: 'PAY DATE',
      payPeriod: 'PAY PERIOD',
      payGroup: 'Pay Group',
      runningLoader: {
        texts: {
          employees: 'Pulling employees into the payroll',
          manual: 'Finding manual checks',
          todos: 'Finding payroll to-dos',
          unapproved: 'Looking for unapproved timesheets',
          voids: 'Finding void checks'
        },
        message: "What's happening behind the scenes?"
      },
      runPayroll: 'Start payroll',
      previewPayroll: 'Preview',
      badgeMessage: {
        ready: 'READY TO RUN',
        unapprovedTimesheets: 'UNAPPROVED TIME',
        unreceivedTips: 'UNRECEIVED TIPS',
        unapprovedTimeAndTips: 'UNAPPROVED TIME & TIPS',
        unableToPost: 'CANNOT PROCESS PAYROLL'
      }
    }
  },
  preview: {
    header: {
      title: 'Preview',
      message:
        'Preview items attached to this payroll. When you are confident everything is accounted for, click Next to bring all items in and advance to the next step.'
    },
    loaderMsg: 'Importing hours, earnings, and deductions…',
    messages: {
      description: '{{amount}} {{title}} for {{whom}} ({{author}})'
    },
    importAudit: {
      description: '{{filename}} imported by {{author}}'
    },
    expenses: {
      description: '{{amount}} Approved Expenses'
    },
    postDeadline: 'POST DEADLINE',
    timesheets: {
      addAlohaImport: 'Add Aloha Import',
      viewAlohaImport: 'View Aloha Import',
      addCustomImport: 'Add Custom Import',
      viewCustomImport: 'View Custom Import',
      approved: 'Approved Timesheets',
      noApproved: 'No approved timesheets found',
      title: 'Timesheets',
      unapprovedNotPaid: 'Unapproved time will not be paid in payroll'
    }
  },
  preflight: {
    stepper: 'Preview payroll',
    activity: {
      empty: 'There is no recent activity for this payroll.',
      expense:
        'An expense report for <strong>{{amount}} for {{employeeName}}</strong> was approved',
      expensePlural:
        'Expense reports for <strong>{{amount}} for {{employeeName}}</strong> were approved',
      expenseCombined:
        'Expense reports for <strong>{{amount}} for {{numEmployees}} employees</strong> were approved',
      title: 'Recent activity',
      message:
        '{{author}} {{action}} a <strong>{{amount}} {{name}} for {{whom}}</strong>',
      action: {
        recorded: 'recorded',
        approved: 'approved',
        scheduled: 'scheduled'
      }
    },
    rollover: {
      title:
        'You did not submit {{totalCount}} {{ifPlural}} in {{previousYear}}',
      nonAdminTitle:
        'Your business did not submit {{totalCount}} {{ifPlural}} in {{previousYear}}',
      pluralPayrolls: 'payrolls',
      singularPayroll: 'payroll',
      descriptionLessThan5:
        'In order to pay your team in {{currentYear}}, we need to close any payrolls you have not submitted in {{previousYear}}. Either archive payrolls to continue paying your team in {{currentYear}}, or contact us to submit any {{previousYear}} payrolls.',
      descriptionGreaterThan5:
        'In order to pay your team in {{currentYear}}, we need to close any payrolls you have not submitted in {{previousYear}}. Either archive payrolls to continue paying your team in {{currentYear}}, or contact us to submit any {{previousYear}} payrolls.',
      nonAdminDescription:
        'In order to pay your team in {{currentYear}}, we need to close any payrolls your business did not submit in {{previousYear}}. Contact us to continue paying your team in {{currentYear}}.',
      table: {
        payGroup: 'Pay group',
        payFrequency: 'Pay frequency',
        payPeriod: 'Pay period',
        checkDate: 'Check date'
      },
      contactUs: 'Contact us',
      archive: 'Archive payrolls',
      archivingPayrolls: 'Archiving {{ifPlural}}',
      archivedPayrolls: 'Archived {{ifPlural}}'
    },
    employeeCard: {
      delta: 'Change from last pay period',
      hourly: 'Hourly employees',
      salary: 'Salary employees',
      title: 'Employees',
      viewDetails: 'View details',
      subtitle: ' as of today'
    },
    tipsCard: {
      title: 'Tips',
      amount: 'Total amount',
      overtime: 'Overtime',
      unreceived: '{{number}} days unreceived'
    },
    hoursCard: {
      title: 'Hours'
    },
    error:
      'Sorry, we were unable to load this information. Please try refreshing the page. If the problem persists, please contact customer care.',
    modals: {
      timesheets: {
        title: 'Hours by earnings',
        subtitle: 'Breakdown of all hours worked by earnings',
        header: 'Earning',
        emptyState: 'There are no timesheets associated with this payroll.'
      },
      tips: {
        title: 'Tips',
        emptyState: 'There are no tips associated with this payroll.',
        subTitle: {
          normal: 'These tips will be paid to your employees in this payroll',
          pooled:
            'These tips include non-cash and recorded cash tips. Send tips from Tips Management in POS to Payroll before approving your timesheets.'
        },
        status: {
          inProgress: 'IN PROGRESS',
          ready: 'READY',
          noTips: 'NO TIPS'
        },
        header: {
          days: 'Days worked this period',
          tips: 'Tips'
        },
        footer: {
          viewTimeAndTips: 'View time and tips',
          viewTipsInPayroll: 'View tips in payroll',
          viewTipsManagement: 'View Tips Management'
        }
      },
      employees: {
        title: 'Employee comparison',
        tabs: {
          compare: 'Compare to last period',
          employees: 'All employees',
          content: {
            compare: {
              incoming: {
                title: 'Incoming employees',
                description:
                  "Employees who will be paid on this cycle but weren't paid on the last payroll cycle. Employees with Payroll To Dos might not be included.",
                emptyState: 'There are 0 incoming employees on this payroll.'
              },
              outgoing: {
                title: 'Outgoing employees',
                description:
                  "Employees who aren't paid but were paid on this payroll's last cycle.",
                emptyState: 'There are 0 outgoing employees on this payroll.'
              }
            },
            employees: {
              title: 'Employees paid on the payroll',
              description:
                'Employees with Payroll To Dos might not be included.',
              emptyState:
                'There are 0 employees to be paid on this payroll at this time. This may be because employees have not yet worked within this pay cycle. If you believe this is an issue, please contact customer care.'
            }
          }
        }
      }
    },
    timesheetsPanel: {
      delta: 'Change from this time last pay period',
      overtimeHours: 'Overtime Hours',
      title: 'Timesheets',
      totalHours: 'Total Hours',
      unapproved: '{{number}} unapproved',
      unapprovedNoCount: 'Unapproved',
      viewDetails: 'View Details'
    },
    hoursGraph: {
      legend: {
        currentPayPeriod: 'Hours to be paid this period',
        previousPayPeriod: 'Hours paid last period'
      },
      title: 'Hours',
      timesheetsLink: 'View by earnings',
      emptyState: 'No timesheets have been recorded for this payroll'
    }
  },
  spreadsheet: {
    stepper: 'Employee earnings',
    empty: {
      title: 'There are no employees on this payroll',
      supplement:
        'When employees in this pay group have earnings for this pay period, they will show up here'
    },
    card: {
      employee: {
        deleteEmployee: 'Delete Employee',
        payViaCheck: 'Pay via Check',
        grossToNet: 'Calculate Gross to Net',
        additionalPayCheck: 'Create Additional Paycheck'
      }
    },
    payBy: {
      title: 'Manage pay checks for {{employee}}'
    },
    paidByCheck: 'Employee to be paid via check*',
    payViaCheck: {
      title: 'Pay via check employee: {{employee}}',
      text: 'Would you like to override default payment method to pay via paper paycheck?',
      description:
        'If you choose to override default payment method, the employee will receive a physical paycheck instead of what might otherwise be a deposit to their bank account(s) on file. This feature is useful if the employee has requested a one-time payment by check, or in cases when you prefer to deliver a particular payment (e.g. a year end bonus) via paper paycheck.',
      payAllDropdown: 'Pay all via check',
      payAllTitle: 'Pay all via check?',
      payAllBody:
        'With this option, all employees on this payroll will receive a physical paycheck instead of what might otherwise be a deposit to their bank account(s) on file. Please use carefully, as this cannot be undone.'
    },
    actions: {
      confirm: {
        body: 'You are about to calculate your payroll. Note that this is NOT the final step of payroll. Once the payroll is calculated, you will have an opportunity to review for accuracy and finalize/post the payroll.',
        header: 'Are you ready to calculate payroll?'
      }
    },
    employeeList: {
      employee: 'Employee',
      hours: 'Hours',
      totalEarnings: 'Earnings'
    },
    deductions: {
      table: {
        headings: {
          deduction: 'Deduction',
          amount: 'Amount'
        }
      },
      form: {
        add: {
          title: 'Add Deduction: {{employee}}',
          bulkTitle: 'Bulk Add Deductions'
        },
        edit: {
          title: 'Edit Deduction: {{employee}}'
        },
        addDeduction: 'Add Deduction',
        searchEmployees: 'Search employees...',
        employee: {
          label: 'Which employee(s) will receive this deduction?',
          blank: 'No matching employees'
        },
        amount: 'Amount',
        deductionType: 'Deduction Type',
        flatAmount: 'Flat amount',
        percentageGrossPay: '% of Gross Pay',
        validation: {
          employees: {
            min: 'This deduction must be applied to at least one employee'
          }
        }
      }
    },
    earnings: {
      table: {
        headings: {
          earning: 'Earning',
          location: 'Location',
          job: 'Job',
          rate: 'Rate',
          hours: 'Hours',
          amount: 'Amount'
        },
        weekTwo: ' (Week 2)'
      },
      form: {
        add: {
          title: 'Add Earning: {{employee}}',
          bulkTitle: 'Bulk Add Earnings'
        },
        edit: {
          title: 'Edit Earning: {{employee}}'
        },
        addEarning: 'Add Earning',
        searchEmployees: 'Search employees...',
        employee: {
          label: 'Which employee(s) will receive this earning?',
          blank: 'No matching employees'
        },
        earningRate: {
          label: 'Earning Rate',
          labelOT: 'Base Earning Rate'
        },
        workWeek: {
          label: 'Associated Work Week',
          helper:
            'To which week of the pay period does this earning apply? This selection is used to inform overtime and tip makeup calculations.'
        },
        earningCode: {
          label: 'Earning Code'
        },
        hours: {
          label: 'Hours',
          salaried: {
            helper:
              'This is a salaried earning, therefore hours are used in reporting only.'
          }
        },
        amount: {
          label: 'Amount'
        },
        checkCode: {
          label: 'Earning frequency',
          helper:
            'This helps inform taxation. This does not create new earnings in future payrolls.'
        },
        isFirstWeek: {
          first: 'First',
          second: 'Second'
        },
        validation: {
          employees: {
            min: 'This earning must be applied to at least one employee'
          }
        },
        actions: {
          change: 'Change earning',
          add: 'Add earning'
        },
        advancedOptions: {
          label: 'Advanced Options',
          description:
            '(Company, location, job, rate, work week, earning frequency)'
        }
      }
    },
    addPayCheck: {
      title: 'Create additional Paycheck: {{name}}',
      helperText:
        'This helps inform taxation. This does not create new earnings in future payrolls.',
      confirmButton: 'Add paycheck'
    },
    addEmployee: {
      title: 'Add New Employee',
      confirmButton: 'Add employee',
      helperText: 'You may add any employee from this pay group',
      showTerminated: 'Include terminated employees'
    },
    deleteEmployee: {
      title: 'Delete employee: {{name}}',
      loadingText: 'Deleting...',
      confirmButton: 'Delete Employee',
      helperText:
        'Deleting removes this employee and all associated earnings/deductions from the payroll. Are you sure you want to delete?'
    },
    g2n: {
      modal: {
        error: 'Sorry, we are unable to calculate gross to net at this time',
        title: 'Gross to Net Preview: {{name}}',
        totals: {
          gross: 'Gross Earnings',
          deductions: 'Deductions',
          taxes: 'Taxes',
          net: 'Net'
        }
      },
      tables: {
        earnings: { title: 'Earnings' },
        deductions: { title: 'Deductions' },
        tax: {
          title: 'Taxes',
          headings: {
            tax: 'Tax',
            amount: 'Amount',
            wages: 'Subject Wages',
            ytd: 'YTD'
          }
        }
      }
    },
    page: {
      addEmployee: 'Add employee',
      addBulkEarnings: 'Bulk add earnings',
      addBulkDeductions: 'Bulk add deductions',
      allLocations: 'All Locations',
      allJobs: 'All Jobs',
      employeeName: 'Employee Name'
    },
    condensedView: 'Condensed View'
  },
  review: {
    stepper: 'Review & submit',
    sections: {
      paperChecks: {
        header: 'Paper Checks',
        instructions:
          'Please remember to use Toast check stock to ensure proper print formatting.',
        download: 'Download paper checks',
        audit: '{{employee}} Downloaded Checks at {{time}}',
        loading:
          'The paper check file is currently being generated. This can take a few minutes, so please feel free to check back later.',
        description:
          '{{count}} employees require payment by paper check. Please print and deliver check(s) on {{checkDate}}.',
        settings: 'Paycheck Delivery Settings',
        error:
          "Sorry, we couldn't load the paper check info. Please refresh the page or try again later.",
        showMore: 'Show More',
        showLess: 'Show Less',
        headerNoChecks:
          'No employees on this payroll are scheduled to receive paper checks. To modify check delivery settings, go to <0>Paycheck Delivery Settings.</0>'
      },
      payownway: `You are using the self-written method to pay employees without Direct Deposit. Typically, this means you
      will write live checks out of your own checkbook, or deliver employee Net Pay(s) in cash. To get a list of employees you need to ensure are paid, 
      please consult the Check Register Report below.`,
      toastPrinted: {
        header:
          'Toast Payroll will deliver <strong>{{checks}} checks</strong> for <strong>{{ees}} employees</strong> to your addresses on file in <0>Paycheck Delivery Settings.</0>',
        table: {
          headers: {
            destination: 'Checks Package Destination',
            checks: '# of checks',
            trackingNumber: 'Tracking',
            trackingNumberUnavailable: 'Tracking number unavailable'
          }
        },
        warning:
          'Check delivery typically costs $20/package. Save on future payroll shipping costs by switching to Self-Written or Self-Printed.'
      }
    },
    accountingSummary: {
      cashTips: 'Cash tips',
      cashTipsV1: 'Cash (not paid in payroll)',
      grossPay: 'Gross pay',
      nonCashTips: 'Non-cash tips',
      nonCashTipsV1: 'Non-cash (paid in payroll)',
      taxes: 'Employer taxes',
      tips: 'Tips',
      title: 'Accounting Summary',
      total: 'Total amount',
      totalV1: 'Total',
      lastPayroll: 'Last payroll',
      estimatedWithdrawal: 'Estimated withdrawal',
      withdrawalTooltip:
        'Estimated funds to be debited by Toast, not inclusive of paper checks and paid cash tips.'
    },
    action: {
      cancel: 'Submit later',
      cancelV1: 'Post later',
      close: 'Close',
      edit: 'Previous',
      importTaxes: 'Import Taxes',
      makeAdjustments: 'Make Adjustments',
      post: 'Submit payroll',
      postPayroll: 'Post payroll'
    },
    calculating: {
      behindScenes: {
        message: "What's happening behind the scenes?",
        texts: {
          deductions: 'Ensuring employee deductions are within limits',
          directDeposits: 'Finding employee direct deposit information',
          employeeTaxes: 'Calculating employee taxes',
          minimumWage: 'Ensuring employees are making minimum wage',
          netPay: 'Calculating Net Pay',
          reports: 'Generating reports',
          taxableIncome: 'Calculating taxable income',
          vendor: 'Finding vendor information for deduction payments'
        }
      },
      title: 'Calculating Payroll...'
    },
    loading: 'Loading Payroll...',
    checkDate: 'Check date',
    employeeEarnings: {
      employeeCount: 'No. of Employees',
      title: 'Employee Earnings',
      totalEarnings: 'Earnings'
    },
    employeeSummary: {
      hoursPaid: 'Hours paid',
      onPayroll: 'Employees on payroll',
      otHours: 'OT',
      paidViaCheck: 'Paid via check',
      paidViaDirectDeposit: 'Paid via direct deposit',
      regularHours: 'Regular',
      salariedEmployees: 'Salary employees',
      title: 'Employee Summary'
    },
    header: {
      message:
        'Double-check your payroll before you submit. This is the last step!',
      title: 'Review'
    },
    missingSSNAlert: {
      content:
        'You have {{count}} employee(s) in this pay group missing SSNs. <strong>This may result in penalties which you will be responsible for paying</strong>, will impact tax documentation, and make it difficult to file taxes.',
      link: 'Add employee SSNs'
    },
    modals: {
      edit: {
        error: {
          message:
            'We were unable to edit your payroll, please contact support or retry',
          title: 'Unable to edit payroll'
        }
      },
      post: {
        signature: {
          title: 'Your signature is required for payroll processing',
          description:
            "Please submit the bank account holder's signature that will be printed on any physical checks Toast may generate on your behalf.",
          table: 'Bank Accounts',
          clear: 'Clear',
          caption:
            'If you have multiple bank accounts for your business and wish to use distinct signatures for each, please reach out to our customer care team. By signing here, you attest that you are the bank account holder and have the authority to sign checks for this account.'
        },
        confirmation: {
          button: 'Submit payroll',
          buttonV1: 'Post payroll',
          message:
            'By submitting, you acknowledge that you have reviewed this payroll for completeness and accuracy. This action cannot be undone.',
          buttonNext: 'Next',
          title: 'Submit Payroll?',
          achConsent: {
            confirm:
              'I agree to be bound by the Toast Payroll & Team Management ACH Authorization',
            legal:
              'By checking the box below, Merchant agrees to be bound by the <0>Toast Payroll & Team Management ACH Authorization</0>, authorizing Toast, Inc., StratEx HoldCo, LLC, Toast Processing Services LLC, Strategy Execution Partners, LLC and their designated agents and representatives (each, as applicable to the activities that such party may perform) to initiate one-time and recurring credits and debits, as applicable, to the Bank Account(s) ending in {{bankAccounts}}, pursuant to the Toast Payroll & Team Management Service Agreement (the “Agreement”).',
            title: 'We need your ACH authorization to proceed.',
            titleUpdated:
              'You have modified a bank account associated with your Toast Payroll account; we need your ACH authorization to proceed.'
          },
          prodValidation: {
            title: 'Are you sure you want to submit payroll?',
            description:
              'You are about to make changes to a real payroll customer account. Do not submit payroll unless you are processing payroll on the customer’s behalf.',
            checkbox:
              'I have the responsibility and permission to process payroll for this customer.'
          },
          statusCheck: 'Checking posting status...'
        },
        error: {
          message:
            'We were unable to post your payroll, please contact support or retry',
          title: 'Unable to post payroll'
        },
        missedDeadline: {
          button: 'Post',
          title: 'You have missed the due date for this payroll',
          description:
            'Select how you want to pay your employees and proceed to the next step to process payroll',
          postponeOption: 'Attach this payroll to the next pay date',
          postponeMsg: 'Pay your employees by {{newCheckDate}}',
          payViaCheckOption: 'Pay by paper check',
          payViaCheckMsg: "Print your employees' paper checks"
        }
      }
    },
    noteworthy: {
      EMPLOYEES_WITHOUT_NETPAY: '{{count}} employee with $0 net pay',
      EMPLOYEES_WITHOUT_NETPAY_NONE: 'No employees with $0 net pay',
      EMPLOYEES_WITHOUT_NETPAY_plural: '{{count}} employees with $0 net pay',

      EMPLOYEES_WITH_TIP_MAKEUP: '{{count}} employee with tip makeup',
      EMPLOYEES_WITH_TIP_MAKEUP_NONE: 'No employees with tip makeup',
      EMPLOYEES_WITH_TIP_MAKEUP_plural: '{{count}} employees with tip makeup',

      TERMINATIONS: '{{count}} terminated employee paid',
      TERMINATIONS_NONE: 'No terminated employees paid',
      TERMINATIONS_plural: '{{count}} terminated employees paid',

      titleUpdated: 'Highlights and Exceptions',
      title: 'Noteworthy',
      UNPAID_ACTIVE_EMPLOYEES: '{{count}} active employee not paid',
      UNPAID_ACTIVE_EMPLOYEES_NONE: 'All active employees paid',
      UNPAID_ACTIVE_EMPLOYEES_plural: '{{count}} active employees not paid'
    },
    payDate: 'Pay date',
    payrollSummary: {
      checkDate: 'Check date',
      postDeadline: 'Submission deadline',
      postDeadlineV1: 'Post deadline',
      payperiod: 'Pay period',
      title: 'Payroll Summary',
      bankAccount: 'Bank account'
    },
    postDeadline: 'POST DEADLINE',
    postedConfirmation: {
      return: 'Return to Payroll Dashboard',
      subTitle: 'Paper checks and direct deposit details are available below.',
      title: 'Payroll posted successfully!'
    },
    reports: {
      checkRegisterReport: 'Check register',
      directDepositReport: 'Direct deposit list',
      employeeDetailReport: 'Employee detail',
      employeeDetailReportByDept: 'Employee detail by dept',
      employeeDetailReportByPosition: 'Employee detail by position',
      laborDistributionReport: 'Labor distribution',
      payrollSummaryReport: 'Payroll summary',
      payrollExceptionReport: 'Payroll exception',
      employeeComparisonReport: 'Employee comparison',
      laborDistributionReportByDepartment: 'Labor distribution by department',
      laborDistributionReportByLocation: 'Labor distribution by location',
      laborDistributionReportByJob: 'Labor distribution by job',
      journalEntryReport: 'Journal entry report',
      checkRegisterReportV1: 'Check Register',
      directDepositReportV1: 'Direct Deposit List',
      employeeDetailReportV1: 'Employee Detail',
      employeeDetailReportByDeptV1: 'Employee Detail by Dept',
      employeeDetailReportByPositionV1: 'Employee Detail by Position',
      laborDistributionReportV1: 'Labor Distribution',
      payrollSummaryReportV1: 'Payroll Summary',
      payrollExceptionReportV1: 'Payroll Exception',
      employeeComparisonReportV1: 'Employee Comparison',
      laborDistributionReportByDepartmentV1: 'Labor Distribution by Department',
      laborDistributionReportByLocationV1: 'Labor Distribution by Location',
      laborDistributionReportByJobV1: 'Labor Distribution by Job',
      journalEntryReportV1: 'Journal Entry Report',
      title: 'Reports'
    },
    withdrawalSummary: {
      title: 'Toast Payroll Withdrawal Receipt',
      confirmation: 'Confirmation Code: {{payperiodUuid}}',
      description: 'Your bank account(s) will be debited:',
      liveChecks: '<0>{{liveChecks}}</0> for live checks',
      directDeposits: '<0>{{directDeposits}}</0> for direct deposits ',
      vendorPayments: '<0>{{vendorPayments}}</0> for vendor payments',
      payrollFees: '<0>{{collectionFees}}</0> for Toast Payroll fees',
      taxes:
        '<0>{{taxes}}</0> for taxes (not inclusive of any requested credits)',
      nonCashReminder:
        'This receipt does not include non-cash withdrawals. See <0>Payroll Summary Report</0> for additional details.'
    },
    withdrawalEstimate: {
      title: 'Estimated Payroll Withdrawal',
      description: 'Your estimated funds to cover this payroll:',
      toastFunds: {
        description: 'Funds to be collected by Toast',
        value: '<0>{{value}}</0> for Toast withdrawals'
      },
      externalFunds: {
        taxes: '<0>{{value}}</0> for non-Debited Taxes',
        description: 'Funds NOT collected by Toast',
        value: '<0>{{value}}</0> for employee or vendor paper checks'
      },
      note: `You will see a final withdrawal receipt containing the funds Toast will collect from your bank account(s) for this payroll.
         See <0>Payroll Summary Report</0> for additional details.`
    },
    missedDeadline: {
      error: 'Unable to find new check date. Please contact support.'
    }
  },
  reports: {
    employeeComparison: {
      title: 'Employee Hours & Pay Comparison',
      table: {
        employeeName: 'Employee Name',
        previousHours: 'Previous Hours',
        currentHours: 'Current Hours',
        change: 'Change',
        previousGrossPay: 'Previous Gross Pay',
        currentGrossPay: 'Current Gross Pay'
      },
      totals: 'Totals',
      emptyText: 'No Records Found'
    }
  },
  unapproved: {
    unapprovedTimesheets: '{{number}} unapproved timesheets',
    unapprovedTimesheets_SINGULAR: '{{number}} unapproved timesheet'
  }
}
