import { ISerializer } from '../serializers'
import {
  defaultDeleteFetchArgs,
  defaultGetFetchArgs,
  defaultPostFetchArgs,
  defaultUpdateFetchArgs
} from '../utils/fetchArgs'

export interface QueryParam {
  key: string
  value: string
}

export interface IStore<InputType, ResultType = InputType> {
  /**
   * gets the urls with the supplied query params based off of the endpointUrl
   *
   * @param queryParams
   */
  getUrl(queryParams?: Array<QueryParam>): string
  applyRouteParams(url: string, params: any): void
  /**
   * Creates a GET request to return a single item
   *
   * This response will then be deserialized with the resources deserialize method
   *
   */
  findResource(): Promise<ResultType>

  /**
   * Creates a GET request to return a single item and appends the query string id={id}
   *
   * @param id the id to find
   */
  findRecord(id: string): Promise<ResultType>

  /**
   * Creates a GET request to return all items
   *
   * This response will then be deserialized with the resources deserialize method
   */
  findAll(): Promise<Array<ResultType>>

  /**
   * Creates a GET request to return items based on the supplied query params
   *
   * This response will then be deserialized with the resources deserialize method
   *
   * @param queryParams
   */
  query(
    filters?: Array<QueryParam>,
    sort?: Array<QueryParam>,
    page?: QueryParam
  ): Promise<Array<ResultType>>

  /**
   * Creates a GET request to return a single item based on the supplied query params
   *
   * This response will then be deserialized with the resources deserialize method
   * @param queryParams
   */
  queryRecord(params?: Array<QueryParam>): Promise<ResultType>

  /**
   * Creates a POST request to save data to the server.
   *
   * This response will then be deserialized with the resources deserialize method
   *
   * @param model the model be serialized, with the resources serialize method, as the JSON payload
   */
  createRecord(model: InputType): Promise<ResultType>

  /**
   * Creates a DELETE request to delete data from the server
   *
   * This response will then be deserialized with the resources deserialize method
   *
   * @param model the model will be serialized, with the resources serialize method, as the JSON payload
   */
  deleteRecord(model: InputType): Promise<ResultType>

  /**
   * Creates a custom request (POST by default) to update data on the server
   *
   * This response will then be deserialized with the resources deserialize method
   *
   * @param model the model will be serialized, with the resources serialize method, as the JSON payload
   * @param method the http method that will be used, by default POST. PUT can be used
   */

  updateRecord(model: InputType, method?: string): Promise<ResultType>

  /**
   * unimplemented
   * @param models
   */
  createRecords(models: Array<InputType>): Promise<Array<ResultType>>
}

export class FetchStore<InputType, ResultType = InputType>
  implements IStore<InputType, ResultType>
{
  private endpointUrl = ''

  constructor(
    readonly endpointTemplate: string,
    readonly serializer: ISerializer<InputType, ResultType>,
    readonly contentType: string
  ) {}

  public applyRouteParams(url: string, params: any): void {
    Object.keys(params).forEach((key) => {
      url = url.replace(`:${key}`, params[key])
    })
    this.endpointUrl = url
  }
  public getUrl(queryParams: Array<QueryParam> = []): string {
    let queryString = ''
    if (queryParams.length > 0) {
      queryString =
        '?' + queryParams.map((p) => `${p.key}=${p.value}`).join('&')
    }
    return `${this.endpointUrl}${queryString}`
  }

  findResource(): Promise<ResultType> {
    return fetch(`${this.getUrl([])}`, defaultGetFetchArgs(this.contentType))
      .then((d) => d.json())
      .then((data: any) => this.serializer.deserialize(data))
  }

  findRecord(id: string): Promise<ResultType> {
    return fetch(
      this.getUrl([{ key: 'id', value: id }]),
      defaultGetFetchArgs(this.contentType)
    )
      .then((d) => d.json())
      .then((data: any) => this.serializer.deserialize(data))
  }

  findAll(): Promise<Array<ResultType>> {
    return this.query()
  }

  query(queryParams: Array<QueryParam> = []): Promise<Array<ResultType>> {
    return fetch(
      this.getUrl(queryParams),
      defaultGetFetchArgs(this.contentType)
    )
      .then((d) => d.json())
      .then((data: Array<any>) =>
        data.map((e) => this.serializer.deserialize(e))
      )
  }

  queryRecord(queryParams: Array<QueryParam> = []): Promise<ResultType> {
    return fetch(
      this.getUrl(queryParams),
      defaultGetFetchArgs(this.contentType)
    )
      .then((d) => d.json())
      .then((data: any) => this.serializer.deserialize(data))
  }

  createRecord(model: InputType): Promise<ResultType> {
    const fetchArgs: RequestInit = {
      ...defaultPostFetchArgs(this.contentType),
      body: JSON.stringify(this.serializer.serialize(model))
    }

    return fetch(this.getUrl([]), fetchArgs)
      .then((d) => d.json())
      .then((data: any) => this.serializer.deserialize(data))
  }

  deleteRecord(model: InputType): Promise<ResultType> {
    const fetchArgs: RequestInit = {
      ...defaultDeleteFetchArgs(this.contentType),
      body: JSON.stringify(this.serializer.serialize(model))
    }

    return fetch(this.getUrl([]), fetchArgs)
      .then((d) => d.json())
      .then((data: any) => this.serializer.deserialize(data))
  }

  updateRecord(model: InputType, method = 'POST'): Promise<ResultType> {
    const fetchArgs: RequestInit = {
      ...defaultUpdateFetchArgs(this.contentType, method),
      body: JSON.stringify(this.serializer.serialize(model))
    }

    return fetch(this.getUrl([]), fetchArgs)
      .then((d) => d.json())
      .then((data: any) => this.serializer.deserialize(data))
  }

  createRecords(_models: Array<InputType>): Promise<Array<ResultType>> {
    // const fetchArgs: RequestInit = {
    //   ...defaultPostFetchArgs,
    //   body: JSON.stringify(
    //     models.map(model => this.serializer.serialize(model))
    //   )
    // }
    console.error(
      'createRecords is not implemented for:',
      this.endpointTemplate
    )
    return Promise.resolve([])
  }
}
